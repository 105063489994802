<template>
  <v-container
    class="mt-3"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
        :lg="this.$auth.check({ stats: 'performance' }) ? 3 : 4"
      >
        <monthly-lab-results
          :hide-trend="!this.$auth.check({ stats: 'performance' })"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        :lg="this.$auth.check({ stats: 'performance' }) ? 3 : 4"
      >
        <daily-lab-results
          :title="$t('charts.lab_results_this_week')"
          :hide-trend="!this.$auth.check({ stats: 'performance' })"
        />
      </v-col>

      <v-col
        v-if="this.$auth.check({ stats: 'performance' })"
        cols="12"
        md="6"
        lg="3"
      >
        <hourly-activity />
      </v-col>

      <v-col
        cols="12"
        md="6"
        :lg="this.$auth.check({ stats: 'performance' }) ? 3 : 4"
      >
        <sources-chart
          :hide-trend="!this.$auth.check({ stats: 'performance' })"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <focused-lab-results
          xtext="dailyLabResultChart.data.series ? dailyLabResultChart.data.series[0][dailyLabResultChart.data.series[0]?.length - 1] + ' Received Today' : null"
        />
        <stats
          v-if="this.$auth.check({ stats: 'performance' })"
          :cols="$vuetify.breakpoint.mobile ? 12 : 6"
          :metrics="[
            'providers',
            'facilities',
            'patients',
            'microbes'
          ]"
        />
        <data-map
          v-if="this.$auth.check({ stats: 'locations' })"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <lab-result-statuses />
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <material-stats-card
              color="indigo lighten-1"
              icon="fal fa-stopwatch"
              :title="$t('charts.time_to_approval')"
              :value="$moment.utc(approval_time.response_time * 1000).format('H:mm:ss')"
              sub-icon="mdi-calendar"
              :sub-text="$t('charts.last_x_units', { value: '30', units: $tc('day', 2)})"
              :loading="loading.approval_time"
              @refresh="fetchApprovalTime(true)"
            />
          </v-col>

          <v-col
            v-if="this.$auth.check({ financials: 'view' })"
            cols="12"
            sm="6"
          >
            <material-stats-card
              color="success"
              icon="fal fa-robot"
              :title="$t('charts.auto_approval')"
              :value="approval_time.interpreted.auto / (approval_time.interpreted.human + approval_time.interpreted.auto) | percentage"
              sub-icon="fal fa-badge-check"
              :sub-text="autoStats"
              :loading="loading.approval_time"
              @refresh="fetchApprovalTime(true)"
            >
              <template #sub-text>
                <span
                  v-for="status in ['high confidence', 'pending approval']"
                  :key="status"
                  class="my-n1 mr-1"
                >
                  <status-chip
                    :key="status"
                    :status="status"
                    :text="$options.filters.percentage(approval_time[status.replace(/\s+/g, '_')] / approval_time.total_results)"
                    dot
                  />
                </span>
              </template>
            </material-stats-card>
          </v-col>

          <v-col
            v-if="$auth.check({ financials: 'view' })"
            cols="12"
            sm="6"
            lg="6"
          >
            <material-stats-card
              color="light-green"
              icon="fal fa-coins"
              title="Est. Revenue MTD"
              :value="activity.revenue | currency"
              sub-icon="mdi-calendar-month"
              :sub-text="(activity.forecast ? activity.forecast : (activity.revenue / $moment().diff($moment().startOf('month'), 'hours')) * $moment().daysInMonth() * 24) | currency"
              :loading="loading.activity"
              @refresh="fetchActivity()"
            />
          </v-col>
          <v-col
            v-if="$auth.check({ clients: ['*', 'view','edit'] })"
            cols="12"
          >
            <client-activity
              :rows="this.$auth.check({ stats: 'locations' }) ? 10 : 5"
            />
          </v-col>
          <!-- <v-col
            v-if="$auth.check({ clients: ['*', 'view','edit'] })"
            cols="12"
          >
            <user-status
              :rows="this.$auth.check({ stats: 'locations' }) ? 10 : 5"
            />
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
  //   SourceIcon: () => import('@/components/source/SourceIcon.vue'),
    StatusChip: () => import('@/components/StatusChip.vue'),
  //   ClientIcon: () => import('@/components/ClientIcon.vue'),
    MonthlyLabResults: () => import('@/views/dashboards/widgets/MonthyLabResults.vue'),
    HourlyActivity: () => import('@/views/dashboards/widgets/HourlyActivity.vue'),
    DailyLabResults: () => import('@/views/dashboards/widgets/DailyLabResults.vue'),
    ClientActivity: () => import('@/views/dashboards/widgets/ClientActivity.vue'),
    SourcesChart: () => import('@/views/dashboards/widgets/SourcesChart.vue'),
    LabResultStatuses: () => import('@/views/dashboards/widgets/LabResultStatuses.vue'),
    FocusedLabResults: () => import('@/views/dashboards/widgets/FocusedLabResults.vue'),
    DataMap: () => import('@/views/dashboards/widgets/DataMap.vue'),
    Stats: () => import('@/views/dashboards/widgets/Stats.vue'),
  //   UserStatus: () => import('@/views/dashboards/widgets/UserStatus.vue'),
  },
  metaInfo: {
    title: 'Dashboard',
  },
  data () {
    return {
      lab_results: {
        items: [],
      },
    }
  },
  computed: {
    ...mapState('charts', ['loading', 'activity', 'approval_time', 'statuses']),
    autoStats () {
      if (this.approval_time.potential_match) {
        return 'Confident: ' + this.$options.filters.percentage(this.approval_time.potential_match / this.approval_time.total_results)
            + ' | Pending: ' + this.$options.filters.percentage(this.approval_time.pending_approval / this.approval_time.total_results)
      } else {
        return null
      }
    },
  },
  created () {
    if (!this.$store.state.charts.approval_time.response_time) {
      this.fetchApprovalTime()
    }
    // If the revenue is not set or the time older than 1 hour, fetch the activity
    if (!this.$store.state.charts.activity.revenue || this.$moment().diff(this.$store.state.charts.activity.time, 'hours') > 1) {
      this.fetchActivity()
    }
  },
  methods: {
    ...mapMutations('charts', ['setLabResults', 'setActivity', 'setApprovalTime']),
    fetchActivity ($no_cache = false) {
      this.loading.activity = true
      this.axios.get('charts/activity', {
        params: {
          start_date: this.$moment().startOf('month').format(),
          end_date: this.$moment().endOf('month').format(),
          forecast: true,
          no_cache: $no_cache,
        },
        timeout: 0,
      })
        .then((res) => {
          this.$store.state.charts.activity = res.data
        })
        .catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => { this.loading.activity = false })
    },
    fetchApprovalTime($no_cache = false) {
      this.loading.approval_time = true
      this.axios.get('charts/response_time', {
        params: {
          start_date: this.$moment().subtract(30, 'd').format(),
          no_cache: $no_cache,
        },
      })
        .then((res) => {
          this.$store.state.charts.approval_time = res.data
        })
        .catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => { this.loading.approval_time = false })
    },
  },
}
</script>
